body{
    margin: 0;
    padding: 0;
  }
*{
  box-sizing: border-box;
}

.desktop{
  display: initial;
}
.mobile{
  display: none;
}

@media only screen and (max-width: 768px) {
  .mobile{
    display: initial;
  }
  .desktop{
    display: none;
  }
}